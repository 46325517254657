import React from "react";
import { Button, Space, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import { CheckOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";

// types
import { IColumnSource, IModalColumnSource } from "@src/types/source";

// components
import { DotStatus } from "@src/components/dot-status";
import ColumnSourceModal from "./column-source-modal";
import { config } from "@src/config";

type IProps = {
  dataColumns: IColumnSource[];
  sourceId: string | undefined;
  refetchGetSource: () => void;
};

function ColumnSource({ sourceId, dataColumns, refetchGetSource }: IProps) {
  // refine hooks
  const [modalColumnSource, setModalColumnSource] = React.useState<IModalColumnSource>({
    isOpen: false,
    type: "add",
    column: null,
  });

  const dataSource = dataColumns.map((column) => ({
    ...column,
    key: column.id,
  }));

  function openModalColumnSource({ isOpen, type, column = null }: IModalColumnSource) {
    setModalColumnSource({
      type,
      isOpen,
      column,
    });
  }

  function closeOpenModalColumnSource() {
    setModalColumnSource({
      type: "add",
      isOpen: false,
      column: null,
    });
  }

  const columns: any = [
    {
      title: "Name",
      key: "name",
      width: 300,
      render: (_: string, record: IColumnSource) => (
        <div className="flex items-center break-all">
          <div className="shrink-0">
            <DotStatus active={record.active} />
          </div>
          <div className="ml-1">{record.name}</div>
        </div>
      ),
    },
    {
      title: "Data type",
      dataIndex: "dataType",
      key: "dataType",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: "Created at",
      key: "createdAt",
      width: 150,
      sorter: (a: IColumnSource, b: IColumnSource) => b?.createdAt?.localeCompare(a?.createdAt),
      defaultSortOrder: "ascend",
      render: (_: string, record: IColumnSource) => {
        const timestamp = record?.createdAt?.split("[")[0] || null;
        return timestamp ? dayjs(timestamp).format(config.DATE_FORMAT) : "N/A";
      },
    },
    {
      title: "Action",
      key: "action",
      width: 80,
      render: (_: any, record: IColumnSource) => (
        <Space size="middle" align="start">
          <Tooltip title="Edit">
            <Button
              size="small"
              className="refine-edit-button"
              style={{ color: "rgba(0, 0, 0, 0.88)" }}
              icon={<EditOutlined />}
              onClick={() =>
                openModalColumnSource({
                  isOpen: true,
                  type: "edit",
                  column: record,
                })
              }
            />
          </Tooltip>

          <Tooltip title={record.active ? "Deactivate" : "Active"}>
            <Button
              danger={record.active}
              size="small"
              className="refine-edit-button"
              style={{
                color: record.active ? "" : "#1677FF",
                borderColor: record.active ? "" : "#1677FF",
              }}
              icon={record.active ? <StopOutlined /> : <CheckOutlined />}
              onClick={() => {
                openModalColumnSource({
                  isOpen: true,
                  type: record.active ? "deactivate" : "activate",
                  column: record,
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <ColumnSourceModal
        modalColumnSource={modalColumnSource}
        closeOpenModalColumnSource={closeOpenModalColumnSource}
        sourceId={sourceId}
        refetchGetSource={refetchGetSource}
      />
      <div className="text-right mb-4">
        <Button
          type="primary"
          onClick={() => {
            openModalColumnSource({
              isOpen: true,
              type: "add",
              column: null,
            });
          }}
        >
          Add column
        </Button>
      </div>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
}

export default ColumnSource;
