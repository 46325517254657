import { Button, Space, Table, Tag, Tooltip } from "antd";
import React from "react";
import { EditButton, List, ShowButton } from "@refinedev/antd";
import { SyncOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

// hooks
import { useGetSource } from "@src/hooks/use-get-source";
import { useSyncSource } from "@src/hooks/use-sync-source";

// types
import { IColumnSource, ISource } from "@src/types/source";

// configs
import { PAGE_URL, config } from "@src/config";

function Dashboard() {
  const navigate = useNavigate();
  // states
  const [sourceId, setSourceId] = React.useState<number | null>(null);
  // hooks
  const { dataSource, paginationProps, total, isFetching, refetch: refetchGetSource } = useGetSource();
  const { isFetching: isFetchingSyncSource } = useSyncSource({
    sourceId,
    callbackSuccess: () => {
      refetchGetSource();
      setSourceId(null);
    },
  });

  function handleSync(id: number) {
    setSourceId(id);
  }

  const columns: any = [
    {
      title: "Name",
      key: "displayName",
      width: 200,
      render: (_: string, record: ISource) => (
        <Button type="link" onClick={() => navigate(PAGE_URL.SHOW_SOURCE + `/${record.id}`)}>
          {record.displayName}
        </Button>
      ),
    },
    {
      title: "Latency",
      dataIndex: "latency",
      key: "latency",
      width: 100,
      render: (latency: number) => {
        return <>T-{latency}</>;
      },
    },
    {
      title: "Partitions",
      key: "partitions",
      width: 200,
      render: (_: any, record: ISource) => (
        <div className="overflow-auto max-h-[100px]">
          {record.partitions?.map((partition) => (
            <Tag key={partition} className="mb-1">
              {partition}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Cluster by",
      key: "clusterBy",
      width: 200,
      render: (_: any, record: ISource) => (
        <div className="overflow-auto max-h-[100px]">
          {record.clusterBy?.map((cluster) => (
            <Tag key={cluster} className="mb-1">
              {cluster}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Created at",
      key: "type",
      width: 200,
      sorter: (a: IColumnSource, b: IColumnSource) => b?.createdAt?.localeCompare(a?.createdAt),
      defaultSortOrder: "ascend",
      render: (_: string, record: IColumnSource) => {
        const timestamp = record?.createdAt?.split("[")[0] || null;
        return timestamp ? dayjs(timestamp).format(config.DATE_FORMAT) : "N/A";
      },
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_: any, record: ISource) => (
        <Space size="middle" align="start">
          <Tooltip title="Edit">
            <EditButton hideText size="small" recordItemId={record.id} />
          </Tooltip>
          <Tooltip title="Show">
            <ShowButton hideText size="small" recordItemId={record.id} />
          </Tooltip>
          <Tooltip title="Sync">
            <Button
              size="small"
              className="refine-edit-button"
              style={{ color: "rgba(0, 0, 0, 0.88)" }}
              icon={<SyncOutlined />}
              onClick={() => handleSync(record.id)}
              loading={sourceId === record.id && isFetchingSyncSource}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <List canCreate>
        <Table
          size="middle"
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          pagination={{
            ...paginationProps,
            total,
          }}
        />
      </List>
    </>
  );
}

export default Dashboard;
