import React from "react";

import { IModalColumnSource } from "@src/types/source";

type IProps = {
  modalSource: IModalColumnSource;
};

export const useSourceModal = ({ modalSource }: IProps) => {
  const { titleModal, isDisabledInput, classNameModal } = React.useMemo(() => {
    let titleModal = "";
    let isDisabledInput = false;
    let classNameModal = "";
    switch (modalSource.type) {
      case "add":
        titleModal = "Add";
        break;
      case "edit":
        titleModal = "Edit";
        isDisabledInput = true;
        break;
      case "deactivate":
        titleModal = "Deactivate";
        isDisabledInput = true;
        classNameModal = "secondary-modal";
        break;
      case "activate":
        titleModal = "Activate";
        isDisabledInput = true;
        break;
      case "delete":
        titleModal = "Delete";
        classNameModal = "secondary-modal";
        break;
      default:
        break;
    }
    return {
      titleModal,
      isDisabledInput,
      classNameModal,
    };
  }, [modalSource.type]);

  return {
    titleModal,
    isDisabledInput,
    classNameModal,
  };
};
