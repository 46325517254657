import { useNavigate } from "react-router";

// antd
import { Button } from "antd";

// assets
import bgLogin from "@src/assets/images/bg-login.jpeg";
import IconAmanotes from "@src/assets/images/amanotes_icon.png";

// configs
import { PAGE_URL } from "@src/config";
import { NAME_STORAGE } from "@src/config/storage";

// libs
import { signInWithGoogle } from "@src/libs/firebase";

// hooks
import { useLocalStorage } from "@src/hooks/use-local-storage";
function Login() {
  const [, setAccessToken] = useLocalStorage<Number>(NAME_STORAGE.ACCESS_TOKEN);

  async function handleLogin() {
    const user = await signInWithGoogle();

    if (user) {
      setAccessToken(user.accessToken);
      window.location.href = PAGE_URL.ROOT;
    }
  }

  return (
    <div
      style={{
        background: `url(${bgLogin}) no-repeat center center fixed`,
      }}
    >
      <div style={{ height: "100vh", display: "flex" }}>
        <div className="bg-black/[0.6] w-full px-4 py-4 rounded flex items-center flex-col justify-center">
          <div className="flex flex-col mb-4 my-auto p-4 rounded bg-white max-w-[400px] w-full">
            <div className="text-[20px] font-bold mb-4 text-center">Anomaly Detection</div>
            <img src={IconAmanotes} alt="Amanotes" className="w-[100px] mx-auto" />

            <Button
              size="large"
              type="primary"
              className="mt-4 w-full text-center ant-btn flex justify-center items-center border-0 text-[14px] hover:border no-underline"
              onClick={handleLogin}
            >
              Sign in with Google Auth
            </Button>
          </div>
          <div className="mt-auto text-white">© 2024 Amanotes. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  );
}

export default Login;
