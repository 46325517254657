import { Avatar, Table } from "antd";

// hooks
import { useGetUser } from "@src/hooks/user-get-user";

function Dashboard() {
  const { dataSource, paginationProps, total } = useGetUser();

  const columns: any = [
    {
      title: "User",
      key: "user",
      render: (_: any, record: any) => (
        <div>
          <Avatar shape="square" size={32} icon={<img src={record?.picture} alt="user" />} className="mr-2" />
          {record?.name}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  return (
    <>
      <div className="text-[20px] font-bold mb-5">User</div>
      <Table
        size="middle"
        dataSource={dataSource}
        columns={columns}
        pagination={{
          ...paginationProps,
          total,
        }}
      />
    </>
  );
}

export default Dashboard;
