import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteButton, EditButton, RefreshButton, Show, TextField } from "@refinedev/antd";
import { HttpError, useOne } from "@refinedev/core";
import { Card, Empty, Tabs, TabsProps } from "antd";

// types
import { ISource } from "@src/types/source";

// pages
import ColumnSource from "../components/column-source";
import MetricSource from "../components/metric-source";
import ViewSource from "../components/view-source";

// configs
import { SOURCE_STATUS } from "@src/config/source";
import { PAGE_URL } from "@src/config";

// hooks
import { useSyncSource } from "@src/hooks/use-sync-source";
import LogSource from "../components/log-source";

function ShowDataSource() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  // states
  const [sourceId, setSourceId] = React.useState<string | null>(null);
  // refine hooks
  const {
    data,
    isFetching,
    refetch: refetchGetSource,
    isError,
  } = useOne<ISource, HttpError>({
    id: id ? parseInt(id) : undefined,
    queryOptions: {
      retry: 0,
      enabled: !!id,
    },
  });
  // custom hooks
  const { isFetching: isFetchingSyncSource } = useSyncSource({
    sourceId,
    callbackSuccess: () => {
      refetchGetSource();
      setSourceId(null);
    },
  });

  const dataSource = data?.data || null;

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Column",
      children: (
        <ColumnSource refetchGetSource={refetchGetSource} sourceId={id} dataColumns={dataSource?.columns || []} />
      ),
    },
    {
      key: "2",
      label: "Metric",
      children: (
        <MetricSource refetchGetSource={refetchGetSource} sourceId={id} dataColumns={dataSource?.metrics || []} />
      ),
    },
    {
      key: "3",
      label: "View",
      children: <ViewSource />,
    },
    {
      key: "4",
      label: "Log",
      children: <LogSource sourceId={id} />,
    },
  ];

  function syncSouce() {
    if (id) {
      setSourceId(id);
    }
  }

  function gotoEditPage() {
    navigate(PAGE_URL.EDIT_SOURCE + `/${id}`);
  }

  return (
    <Show
      isLoading={isFetching}
      headerButtons={({ deleteButtonProps, editButtonProps }) => (
        <div>
          <EditButton {...editButtonProps} className="mr-2" onClick={gotoEditPage} />
          {dataSource?.status === SOURCE_STATUS.DRAFT.key && <DeleteButton {...deleteButtonProps} className="mr-2" />}
          <RefreshButton loading={isFetchingSyncSource} onClick={syncSouce}>
            Sync
          </RefreshButton>
        </div>
      )}
    >
      <Card size="small" title="Information" className="mb-3">
        <div>
          <b>Name</b>
        </div>
        <TextField value={dataSource?.displayName} />
        <div className="mt-4 mb-1">
          <b>Reference</b>
        </div>
        <TextField value={dataSource?.reference} />
        <div className="mt-4 mb-1">
          <b>Reference type</b>
        </div>
        <TextField value={dataSource?.referenceType} />
        <div className="mt-4 mb-1">
          <b>Latency</b>
        </div>
        <TextField value={"T-" + dataSource?.latency} />
      </Card>
      {isError ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <Tabs defaultActiveKey="1" items={items} />}
    </Show>
  );
}

export default ShowDataSource;
