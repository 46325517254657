import packageJSON from "../../package.json";

export const config = {
  BACKEND_URL: process.env.VITE_BACKEND_URL || "",
  SIDEBAR_WIDTH: 240,
  RECHART_HEIGHT: 500,
  DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  VERSION: packageJSON.version,
  INITIAL_PAGE_SIZE: 10,
  INITIAL_PAGE_SIZE_OPTIONS: [5, 30, 50],
};

// url
export { PAGE_URL } from "./page-url";
