import React from "react";
import { useCustom } from "@refinedev/core";
import { config } from "@src/config";

type IProps = {
  sourceId: number | string | null;
  callbackSuccess?: () => void;
};

export const useSyncSource = ({ sourceId, callbackSuccess }: IProps) => {
  const { isFetching, isError } = useCustom({
    url: `${config.BACKEND_URL}/source/${sourceId}/sync`,
    method: "post",
    queryOptions: {
      enabled: Boolean(sourceId),
      retry: 0,
    },
    successNotification: () => {
      callbackSuccess && callbackSuccess();
      return {
        message: "Sync source successfully!",
        description: "Success!",
        type: "success",
      };
    },
  });

  return {
    isFetching,
    isError,
  };
};
