import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.VITE_FIREBASE_API_KEY,
  authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VITE_FIREBASE_PROJECT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const res: any = await signInWithPopup(auth, googleProvider);
    return res.user;
  } catch (err) {
    console.error(err);
  }
};

export const signOutWithGoogle = async () => {
  signOut(auth)
    .then(() => {
      window.localStorage.clear();
      window.location.reload();
      console.log("Signed out successfully");
    })
    .catch((error) => {
      // An error happened.
      console.log("Signed out error: ", error);
    });
};
