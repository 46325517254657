import React from "react";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";

function EditDataSource() {
  const { formProps, saveButtonProps, formLoading } = useForm({
    successNotification: () => {
      return {
        message: `Edit data source successfully!`,
        description: "Success!",
        type: "success",
      };
    },
  });

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading} headerButtons={() => <></>}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name="displayName"
          rules={[
            {
              required: true,
              message: "Please input the name of the data source!",
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>

        <Form.Item
          label="Reference"
          name="reference"
          rules={[
            {
              required: true,
              message: "Please input the reference of the data source!",
            },
          ]}
        >
          <Input placeholder="Enter reference" />
        </Form.Item>

        <Form.Item
          label="Reference type"
          name="referenceType"
          initialValue={"BIGQUERY"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            defaultValue={"draft"}
            options={[
              { value: "BIGQUERY", label: "Bigquery" },
              { value: "GCS", label: "GCS", disabled: true },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Latency"
          name="latency"
          rules={[
            {
              min: 0,
              max: 10,
              validator: async (_, value) => {
                if (value < 0 || value > 10) {
                  return Promise.reject("Latency must be between 0 and 10");
                }
              },
            },
          ]}
        >
          <Input min={0} max={10} type="number" placeholder="Enter lantecy" style={{ width: 200 }} />
        </Form.Item>
      </Form>
    </Edit>
  );
}

export default EditDataSource;
