import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

import "@refinedev/antd/dist/reset.css";
import "./styles/index.css";

// components
import ErrorBoundary from "@src/components/error-boundary/error-boundary";
import { LoadingFullScreen } from "@src/components/loading/loading-full-screen";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.Suspense fallback={<LoadingFullScreen />}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.Suspense>,
);
