export type IDataType = {
  key: string;
  label: string;
  color?: string;
};

export const SOURCE_STATUS: Record<string, IDataType> = {
  ACTIVE: {
    key: "ACTIVE",
    label: "Active",
  },
  ARCHIVED: {
    key: "ARCHIVED",
    label: "Archived",
  },
  DRAFT: {
    key: "DRAFT",
    label: "Draft",
  },
};

export const SOURCE_DATA_TYPE: Record<string, IDataType> = {
  STRING: {
    key: "STRING",
    label: "String",
  },
  INTEGER: {
    key: "INTEGER",
    label: "Integer",
  },
  DOUBLE: {
    key: "DOUBLE",
    label: "Double",
  },
  DATE: {
    key: "DATE",
    label: "Date",
  },
  RECORD: {
    key: "RECORD",
    label: "Record",
  },
  BYTES: {
    key: "BYTES",
    label: "Bytes",
  },
  BOOLEAN: {
    key: "BOOLEAN",
    label: "Boolean",
  },
};

export const SOURCE_TYPE: Record<string, IDataType> = {
  CUSTOM: {
    key: "CUSTOM",
    label: "Custom",
  },
  NATIVE: {
    key: "NATIVE",
    label: "Native",
  },
};
