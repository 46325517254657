import { useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { usePagination } from "./use-pagination";

type IProps = {
  sourceId: string | undefined;
};

export const useGetLog = ({ sourceId }: IProps) => {
  const { paginationProps, currentPage, pageSize } = usePagination();
  const { data, isFetching, refetch } = useCustom({
    url: `${config.BACKEND_URL}/source/${sourceId}/logs`,
    method: "get",
    queryOptions: {
      enabled: Boolean(sourceId),
      retry: 0,
    },
    config: {
      query: {
        limit: pageSize,
        offset: currentPage,
      },
    },
  });

  const dataSource = data?.data?.data || [];
  const total = (paginationProps?.pageSize || 0) * (data?.data.pageCount || 0);

  return {
    dataSource,
    total,
    paginationProps,
    isFetching,
    refetch,
  };
};
