import { Button, Table } from "antd";
import dayjs from "dayjs";
import { ReloadOutlined } from "@ant-design/icons";

// types
import { ILogSource } from "@src/types/source";

// hooks
import { useGetLog } from "@src/hooks/use-get-log";
import { config } from "@src/config";

type IProps = {
  sourceId: string | undefined;
};

function LogSource({ sourceId }: IProps) {
  // hooks
  const { dataSource, paginationProps, total, isFetching, refetch: refetchGetSource } = useGetLog({ sourceId });

  const columns: any = [
    {
      title: "Name",
      key: "name",
      width: 200,
      render: (_: string, record: ILogSource) => (
        <div>
          {record.userName} <br />
          <div className="text-[12px]">{record.userEmail}</div>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      width: 200,
    },
    {
      title: "Event Name",
      key: "eventName",
      dataIndex: "eventName",
      width: 100,
    },
    {
      title: "Created at",
      key: "type",
      width: 100,
      sorter: (a: ILogSource, b: ILogSource) => b?.createdAt?.localeCompare(a?.createdAt),
      defaultSortOrder: "ascend",
      render: (_: string, record: ILogSource) => {
        const timestamp = record?.createdAt?.split("[")[0] || null;
        return timestamp ? dayjs(timestamp).format(config.DATE_FORMAT) : "N/A";
      },
    },
  ];

  return (
    <>
      <div className="text-right mb-3">
        <Button type="primary" icon={<ReloadOutlined />} loading={isFetching} onClick={() => refetchGetSource()}>
          Refresh
        </Button>
      </div>
      <Table
        size="middle"
        dataSource={dataSource}
        columns={columns}
        loading={isFetching}
        pagination={{
          ...paginationProps,
          total,
        }}
      />
    </>
  );
}

export default LogSource;
