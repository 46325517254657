import React from "react";
import { useCreate, useCustom, useUpdate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import clsx from "clsx";
import TextArea from "antd/es/input/TextArea";

// types
import { IModalColumnSource } from "@src/types/source";

// configs
import { config } from "@src/config";

// hooks
import { useSourceModal } from "@src/hooks/use-source-modal";

type IProps = {
  sourceId?: string | undefined;
  modalMetric: IModalColumnSource;
  closeOpenModalMetric: () => void;
  refetchGetSource: () => void;
};

function MetricSourceModal({ sourceId, modalMetric, closeOpenModalMetric, refetchGetSource }: IProps) {
  // custom hooks
  const { titleModal, isDisabledInput, classNameModal } = useSourceModal({ modalSource: modalMetric });
  // refine hooks
  const [form] = Form.useForm();
  const { mutate: mutateAddMetric, isLoading: isLoadingAddMetric } = useCreate();
  const { mutate: mutateUpdateMetric, isLoading: isLoadingUpdateMetric } = useUpdate();
  const reason = Form.useWatch("reason", form);

  const { refetch: refetchDeactivate, isFetching: isFetchingDeactivate } = useCustom({
    url: `${config.BACKEND_URL}/source/${sourceId}/metric/${modalMetric.column?.id}/deactivate`,
    method: "patch",
    config: {
      payload: {
        reason: reason || "",
      },
    },
    queryOptions: {
      enabled: false, // disabled when page is login
      retry: 0,
    },
  });
  const { refetch: refetchActivate, isFetching: isFetchingActiave } = useCustom({
    url: `${config.BACKEND_URL}/source/${sourceId}/metric/${modalMetric.column?.id}/activate`,
    method: "patch",
    queryOptions: {
      enabled: false, // disabled when page is login
      retry: 0,
    },
  });

  const initialValues = {
    name: "",
    description: "",
    expr: "",
    reason: "",
  };

  React.useEffect(() => {
    form.setFieldsValue({
      name: modalMetric.column?.name || "",
      description: modalMetric.column?.description || "",
      expr: modalMetric.column?.expr || "",
    });
  }, [modalMetric]);

  function handleAddMetric(values: any) {
    if (!sourceId) return;
    mutateAddMetric({
      resource: `source/${sourceId}/metric`,
      values: [values],
      successNotification: () => {
        form.resetFields();
        closeOpenModalMetric();
        refetchGetSource();
        return {
          type: "success",
          message: "Add metric successfully",
        };
      },
    });
  }

  function editMetric(values: any) {
    if (!modalMetric.column?.id) return;
    mutateUpdateMetric({
      resource: `source/${sourceId}/metric`,
      id: modalMetric.column?.id,
      values,
      meta: {
        method: "patch",
      },
      successNotification: () => {
        form.resetFields();
        closeOpenModalMetric();
        refetchGetSource();
        return {
          type: "success",
          message: "Edit metric successfully",
        };
      },
    });
  }

  function deactivateMetric() {
    if (!modalMetric.column?.id) return;
    refetchDeactivate().then(() => {
      form.resetFields();
      closeOpenModalMetric();
      refetchGetSource();
    });
  }

  function activateMetric() {
    if (!modalMetric.column?.id) return;
    refetchActivate().then(() => {
      form.resetFields();
      closeOpenModalMetric();
      refetchGetSource();
    });
  }

  return (
    <Modal
      title={`${titleModal} metric`}
      open={modalMetric.isOpen}
      className={clsx("modal-abalyzer", classNameModal)}
      width={850}
      onCancel={() => {
        form.resetFields();
        closeOpenModalMetric();
      }}
      okText={titleModal}
      okButtonProps={{
        form: "form-metric",
        htmlType: "submit",
        loading: isLoadingAddMetric || isLoadingUpdateMetric || isFetchingDeactivate || isFetchingActiave,
        danger: modalMetric.type === "deactivate",
      }}
    >
      <Form
        name="form-metric"
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={(values: any) => {
          switch (modalMetric.type) {
            case "add":
              handleAddMetric(values);
              break;
            case "edit":
              editMetric(values);
              break;
            case "deactivate":
              deactivateMetric();
              break;
            case "activate":
              activateMetric();
              break;
            case "delete":
              break;
          }
        }}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input name!" }]}>
          <Input disabled={isDisabledInput} />
        </Form.Item>

        {(modalMetric.type === "add" || modalMetric.type === "edit") && (
          <>
            <Form.Item label="Description" name="description">
              <TextArea />
            </Form.Item>
            <Form.Item label="Expr" name="expr" rules={[{ required: true, message: "Please input expr!" }]}>
              <Input />
            </Form.Item>
          </>
        )}

        {/* field reason */}
        {modalMetric.type === "deactivate" && (
          <Form.Item label="Reason" name="reason" rules={[{ required: true, message: "Please input reason!" }]}>
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default MetricSourceModal;
