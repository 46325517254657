import React, { PropsWithChildren } from "react";

// antd
import { FloatButton, Layout, theme } from "antd";
import { HeartOutlined } from "@ant-design/icons";

// components
import HeaderBar from "./components/header-bar";
import SideBar from "./components/side-bar";

// context
import { useColorContext } from "@src/contexts/color-context";

// configs
import { config } from "@src/config";

const { Header, Content, Footer } = Layout;

const UkuleleLayout: React.FC<PropsWithChildren> = ({ children }) => {
  // states
  const [collapsed, setCollapsed] = React.useState(true);
  // hooks
  const { mode } = useColorContext();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout style={{ marginLeft: collapsed ? 80 : 240 }}>
          <Header
            className="p-0 flex items-center justify-between px-5"
            style={{
              background: `#0050B3`,
            }}
          >
            <HeaderBar />
          </Header>
          <Content
            style={{
              minHeight: 280,
            }}
            className="bg-white p-5"
          >
            {children}
          </Content>
          <div>
            <Footer
              className="border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-t-[1px] border-solid "
              style={{
                textAlign: "center",
                padding: 5,
                fontSize: 12,
                background: mode === "light" ? `#fff` : colorBgContainer,
              }}
            >
              ©2024, Anomaly Detection {config.VERSION} with <HeartOutlined style={{ color: "#f00" }} /> by Amanotes
            </Footer>
          </div>
        </Layout>

        <FloatButton.BackTop />
      </Layout>
      <div>
        <FloatButton.BackTop />
      </div>
    </>
  );
};

export default UkuleleLayout;
