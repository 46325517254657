export const PAGE_URL = {
  ROOT: "/",
  LOGIN: "/login",
  ERROR: "/error",
  ERROR_403: "/error/403",
  ERROR_401: "/error/401",
  SOURCE: "/data-source",
  CREATE_SOURCE: "/data-source/create",
  EDIT_SOURCE: "/data-source/edit",
  SHOW_SOURCE: "/data-source/show",
  USER: "/user",
};
