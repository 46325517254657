import { useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { usePagination } from "./use-pagination";

export const useGetSource = () => {
  const { paginationProps, currentPage, pageSize } = usePagination();
  const { data, isFetching, refetch } = useCustom({
    url: `${config.BACKEND_URL}/source`,
    method: "get",
    queryOptions: {
      enabled: true,
      retry: 0,
    },
    config: {
      query: {
        limit: pageSize,
        offset: currentPage,
      },
    },
  });

  const dataSource = data?.data?.data || [];
  const total = (paginationProps?.pageSize || 0) * (data?.data.pageCount || 0);

  return {
    dataSource,
    total,
    paginationProps,
    isFetching,
    refetch,
  };
};
