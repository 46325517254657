import React from "react";
import { Refine, Authenticated } from "@refinedev/core";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import routerBindings, { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import dataProvider from "@refinedev/simple-rest";
import { UserOutlined } from "@ant-design/icons";

// components
import { UkuleleLayout } from "@src/layouts/ukulele-layout";

// pages
import { Error401 } from "@src/pages/error/401";
import { Error403 } from "@src/pages/error/403";
import { Login } from "@src/pages/login";
import { User } from "./pages/user";
import { CreateDataSource } from "./pages/data-source/create";
import { ShowDataSource } from "./pages/data-source/show";
import { EditDataSource } from "./pages/data-source/edit";
import { Dashboard } from "./pages/dashboard";

// configs
import { PAGE_URL, config } from "./config";

// libs
import { authProvider } from "./libs/auth-provider";
import { axiosInstance, initRequest } from "./libs/axios-instance";

initRequest();

function App() {
  return (
    <BrowserRouter>
      <Refine
        dataProvider={dataProvider(config.BACKEND_URL, axiosInstance)}
        notificationProvider={useNotificationProvider}
        routerProvider={routerBindings}
        authProvider={authProvider}
        resources={[
          {
            name: "source",
            meta: {
              label: "Data Source",
            },
            list: PAGE_URL.ROOT,
            create: PAGE_URL.CREATE_SOURCE,
            edit: PAGE_URL.EDIT_SOURCE + "/:id",
            show: PAGE_URL.SHOW_SOURCE + "/:id",
          },
          {
            name: "User",
            list: PAGE_URL.USER,
            meta: {
              icon: <UserOutlined />,
            },
          },
        ]}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
          useNewQueryKeys: true,
        }}
      >
        <Routes>
          <Route
            element={
              <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                <UkuleleLayout>
                  <Outlet />
                </UkuleleLayout>
              </Authenticated>
            }
          >
            <Route path={PAGE_URL.ROOT}>
              <Route index element={<Dashboard />} />
              <Route path={PAGE_URL.CREATE_SOURCE} element={<CreateDataSource />} />
              <Route path={PAGE_URL.SHOW_SOURCE + "/:id"} element={<ShowDataSource />} />
              <Route path={PAGE_URL.EDIT_SOURCE + "/:id"} element={<EditDataSource />} />
            </Route>
            <Route path={PAGE_URL.USER} element={<User />} />

            <Route path="*" element={<ErrorComponent />} />
          </Route>

          {/* Public routes */}
          <Route
            element={
              <Authenticated key="authenticated-outer" fallback={<Outlet />}>
                <NavigateToResource />
              </Authenticated>
            }
          >
            <Route path={PAGE_URL.LOGIN} element={<Login />} />
          </Route>

          {/* Error Route */}
          <Route
            path={PAGE_URL.ERROR}
            element={
              <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to={PAGE_URL.LOGIN} />}>
                <Outlet />
              </Authenticated>
            }
          >
            <Route path={PAGE_URL.ERROR_401} element={<Error401 />} />
            <Route path={PAGE_URL.ERROR_403} element={<Error403 />} />
          </Route>

          {/* other routes... */}
          <Route element={<Outlet />}>
            <Route path="*" element={<ErrorComponent />} />
          </Route>
        </Routes>
      </Refine>
    </BrowserRouter>
  );
}

export default App;
