import { AuthBindings } from "@refinedev/core";

// configs
import { NAME_STORAGE } from "@src/config/storage";

export const authProvider: AuthBindings = {
  login: async () => {
    return {
      success: true,
    };
  },
  logout: async () => {
    window.localStorage.clear();
    return {
      success: true,
    };
  },
  onError: async (error) => {
    return { error };
  },
  check: async () => {
    const token = localStorage.getItem(NAME_STORAGE.ACCESS_TOKEN);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: {
        message: "Check failed",
        name: "Token not found",
      },
      logout: true,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const user = localStorage.getItem(NAME_STORAGE.USER);
    if (user) {
      return JSON.parse(user);
    }

    return null;
  },
};
