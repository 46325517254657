import React from "react";
import clsx from "clsx";
import { Form, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCreate, useCustom, useUpdate } from "@refinedev/core";

// types
import { IModalColumnSource } from "@src/types/source";

// config
import { config } from "@src/config";

// hooks
import { useSourceModal } from "@src/hooks/use-source-modal";

type IProps = {
  sourceId?: string | undefined;
  modalColumnSource: IModalColumnSource;
  closeOpenModalColumnSource: () => void;
  refetchGetSource: () => void;
};

function ColumnSourceModal({ sourceId, modalColumnSource, closeOpenModalColumnSource, refetchGetSource }: IProps) {
  // custom hooks
  const { titleModal, isDisabledInput, classNameModal } = useSourceModal({ modalSource: modalColumnSource });
  // refine hooks
  const [form] = Form.useForm();
  const { mutate: mutateAddColumn, isLoading: isLoadingAddColumn } = useCreate();
  const { mutate: mutateUpdateColumn, isLoading: isLoadingColumnColumn } = useUpdate();
  const reason = Form.useWatch("reason", form);
  const { refetch: refetchDeactivate, isFetching: isFetchingDeactivate } = useCustom({
    url: `${config.BACKEND_URL}/source/${sourceId}/column/${modalColumnSource.column?.id}/deactivate`,
    method: "patch",
    config: {
      payload: {
        reason: reason || "",
      },
    },
    queryOptions: {
      enabled: false, // disabled when page is login
      retry: 0,
    },
  });
  const { refetch: refetchActivate, isFetching: isFetchingActiave } = useCustom({
    url: `${config.BACKEND_URL}/source/${sourceId}/column/${modalColumnSource.column?.id}/activate`,
    method: "patch",
    queryOptions: {
      enabled: false, // disabled when page is login
      retry: 0,
    },
  });

  const initialValues = {
    name: "",
    description: "",
    dataType: "",
    expr: "",
    reason: "",
  };

  React.useEffect(() => {
    form.setFieldsValue({
      name: modalColumnSource.column?.name || "",
      description: modalColumnSource.column?.description || "",
      dataType: modalColumnSource.column?.dataType || "",
      expr: modalColumnSource.column?.expr || "",
    });
  }, [modalColumnSource]);

  function handleAddColumn(values: any) {
    if (!sourceId) return;
    mutateAddColumn({
      resource: `source/${sourceId}/column`,
      values: [values],
      successNotification: () => {
        form.resetFields();
        closeOpenModalColumnSource();
        refetchGetSource();
        return {
          type: "success",
          message: "Add column successfully",
        };
      },
    });
  }

  function editColumn(values: any) {
    if (!modalColumnSource.column?.id) return;
    mutateUpdateColumn({
      resource: `source/${sourceId}/column`,
      id: modalColumnSource.column?.id,
      values,
      meta: {
        method: "patch",
      },
      successNotification: () => {
        form.resetFields();
        closeOpenModalColumnSource();
        refetchGetSource();
        return {
          type: "success",
          message: "Edit column successfully",
        };
      },
    });
  }

  function deactivateColumn() {
    if (!modalColumnSource.column?.id) return;
    refetchDeactivate().then(() => {
      form.resetFields();
      closeOpenModalColumnSource();
      refetchGetSource();
    });
  }

  function activateColumn() {
    if (!modalColumnSource.column?.id) return;
    refetchActivate().then(() => {
      form.resetFields();
      closeOpenModalColumnSource();
      refetchGetSource();
    });
  }

  return (
    <Modal
      title={`${titleModal} column`}
      open={modalColumnSource.isOpen}
      className={clsx("modal-abalyzer", classNameModal)}
      width={850}
      onCancel={() => {
        form.resetFields();
        closeOpenModalColumnSource();
      }}
      okText={titleModal}
      okButtonProps={{
        form: "form-column-source",
        htmlType: "submit",
        loading: isLoadingAddColumn || isLoadingColumnColumn || isFetchingDeactivate || isFetchingActiave,
        danger: modalColumnSource.type === "deactivate",
      }}
    >
      <Form
        name="form-column-source"
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={(values: any) => {
          switch (modalColumnSource.type) {
            case "add":
              handleAddColumn(values);
              break;
            case "edit":
              editColumn(values);
              break;
            case "deactivate":
              deactivateColumn();
              break;
            case "activate":
              activateColumn();
              break;
            case "delete":
              break;
          }
        }}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input name!" }]}>
          <Input disabled={isDisabledInput} />
        </Form.Item>

        {(modalColumnSource.type === "add" || modalColumnSource.type === "edit") && (
          <>
            <Form.Item label="Description" name="description">
              <TextArea />
            </Form.Item>
            <Form.Item label="Data type" name="dataType" rules={[{ required: true, message: "Please select type!" }]}>
              <Select>
                <Select.Option value="STRING">STRING</Select.Option>
                <Select.Option value="INTEGER">INTEGER</Select.Option>
                <Select.Option value="DOUBLE">DOUBLE</Select.Option>
                <Select.Option value="DATE">DATE</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Expr" name="expr" rules={[{ required: true, message: "Please input expr!" }]}>
              <Input />
            </Form.Item>
          </>
        )}

        {/* field reason */}
        {modalColumnSource.type === "deactivate" && (
          <Form.Item label="Reason" name="reason" rules={[{ required: true, message: "Please input reason!" }]}>
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default ColumnSourceModal;
