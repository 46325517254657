import React from "react";
import { useGetIdentity } from "@refinedev/core";

// antd core
import { Button, Divider, Popover, Switch } from "antd";

// ant icons
import { LogoutOutlined } from "@ant-design/icons";

// components

// types
import { IIdentity } from "@src/types/user";

// context
import { useColorContext } from "@src/contexts/color-context";
import { signOutWithGoogle } from "@src/libs/firebase";

function HeaderBar() {
  const { data: identity } = useGetIdentity<IIdentity>();
  const { mode, setMode } = useColorContext();

  return (
    <div className="relative flex items-center h-full w-full justify-between">
      <div className="flex flex-grow items-center">
        {/* <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          className="text-[16px] w-[64px] h-full text-[#fff]"
        /> */}
      </div>

      <div className="flex items-center">
        <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
          className="mr-4 hidden"
        />
        <Popover
          destroyTooltipOnHide
          arrow={false}
          placement="bottomLeft"
          content={
            <div className="-mx-2">
              <div className="px-4">
                <b>truong.nn@amanotes.com</b> <br />
              </div>
              <Divider className="p-0 m-0 my-2" />
              <div className="flex items-center">
                <Button
                  type="text"
                  className="w-full text-left"
                  icon={<LogoutOutlined size={20} />}
                  onClick={signOutWithGoogle}
                >
                  Logout
                </Button>
              </div>
            </div>
          }
        >
          <div className="flex px-0 py-2 text-sm text-white transition-all ease-nav-brand flex-row items-center cursor-pointer  ml-auto">
            <div className="relative flex items-center mr-[10px]">
              <img className="w-[30px] h-[30px] rounded-full" src={identity?.avatar} alt="User" />
            </div>
            <span className="sm:inline text-ellipsis">truong.nn</span>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default HeaderBar;
